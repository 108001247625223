const FinalResults = ({ data }) => (
  <>
    <div style={{ textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}>
      <h2>User Scores:</h2>
      <table border="1">
        <tr>
          <th>User</th>
          <th>Score</th>
        </tr>
        {data.usersRankedByScore.map(({name, score}, idx) => (
          <tr>
            <td style={{ width: '250px' }}>{name}</td>
            <td style={{ width: '250px' }}>{score}</td>
          </tr>
        ))}
      </table>

      <br />
      <h2>Question Results:</h2>
      <table border="1">
        <tr>
          <th>Question ID</th>
          <th>Question Correct Breakdown</th>
          <th>Average Answer Time (seconds)</th>
          <th>Percentage Correct</th>
        </tr>
        {data.questionResults.map(({questionId, questionCorrectBreakdown, averageAnswerTime, percentCorrect}, idx) => (
          <tr>
            <td style={{ width: '105px' }}>{questionId}</td>
            <td style={{ width: '335px' }}>
              {questionCorrectBreakdown.map(({ answerId, playersCorrect }, idx) => (
                <>
                  Answer ID: {answerId}<br />
                  Players correct: {playersCorrect.length > 0 ? playersCorrect.join(',') : 'N/A'}
                </>
              ))}
            </td>
            <td style={{ width: '105px' }}>{averageAnswerTime}</td>
            <td style={{ width: '105px' }}>{percentCorrect * 100}%</td>                          
          </tr>
        ))}
      </table>
    </div>
  </>
);

export default FinalResults;
