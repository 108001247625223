import * as React from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Dashboard from '@mui/icons-material/Dashboard';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function ResponsiveAppBar() {
  const [api, token, setToken, iter, setToast, getToast, setIter] = useOutletContext();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    const ls = localStorage.getItem('iter');
    if (ls) {
      setIter(parseInt(ls));
    }
  }, []);

  const logout = () => {
    localStorage.removeItem('token');
    setToken(null);
    navigate('/a/login');
    api.post(`/v${iter===2?'1':'2'}/admin/auth/logout`, {}, token)
    .then(() => {});
  };

  let settings = [];

  if (token) {
    settings = [{
      name: 'My Profile',
      url: '/a/profile',
    },{
      name: 'Logout',
      action: logout,
    }];
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <div style={{ backgroundColor: 'red', padding: '5px' }}>
        This is a brand new and experimental frontend. There may be the occasional hiccup in how it behaves. Report issues on the forum.
      </div>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Dashboard
            sx={{ display: { xs: 'flex' }, mr: 1, cursor: 'pointer' }}
            onClick={() => navigate('/a/quizzes')}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
            onClick={() => navigate('/a/quizzes')}
          >
            Toohak
          </Typography>
          <Select
            id="demo-simple-select"
            value={iter}
            label="Iteration"
            onChange={(e) => {
              setIter(e.target.value);
              localStorage.setItem('iter', e.target.value);
            }}
            style={{
              color: '#fff',
              height: '40px',
            }}
          >
            <MenuItem value={2}>Iter2</MenuItem>
            <MenuItem value={3}>Iter3</MenuItem>
          </Select>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Box sx={{ flexGrow: 0 }}>
            <Typography
              variant="h6"
              sx={{ cursor: 'pointer' }}
            >
              {token ? (
                <span onClick={logout}>Logout</span>
              ) : (
                <>
                  <span onClick={() => navigate('/a/login')}>Login</span>
                  &nbsp;|&nbsp;
                  <span onClick={() => navigate('/a/register')}>Register</span>
                </>
              )}
              </Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;