import React from 'react';
import { Outlet, Link, useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

let interval = null;

function PageWrapper() {
  const navigate = useNavigate();
  const [api, token, setToken, iter, setToast, getToast] = useOutletContext();

  React.useEffect(() => {
    if (!token) {
      clearInterval(interval);
      interval = setTimeout(() => {
        navigate('/a/login');
      }, 3000);
    } else {
      clearInterval(interval);
    }
  }, [token]);

  if (token) {
    return <Outlet context={[api, token, setToken, iter, setToast]} />
  }
  return <>Loading...</>;
}

export default PageWrapper;