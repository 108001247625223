import React from 'react';
import { useNavigate, useOutletContext, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Title from '../component/Title';

function Quizzes(props) {

  const [api, token, setToken, iter, setToast] = useOutletContext();
  const [newQuizName, setNewQuizName] = React.useState('');
  const [newQuizDescription, setNewQuizDescription] = React.useState('');
  const navigate = useNavigate();

  const createQuiz = () => {
    api.post(`/v${iter===2?'1':'2'}/admin/quiz`, {
      name: newQuizName,
      description: newQuizDescription,
    }, token)
    .then((data) => {
      setToast('Successfully created new quiz', 'success')
      navigate(`/a/quiz/${data.quizId}/edit`);
    });
  };

  return (
    <>
      <Title>Create New Quiz</Title>
      <p>
        <TextField
          label="Name"
          type="text"
          value={newQuizName}
          onChange={e => 
            setNewQuizName(e.target.value)
          }
        />
      </p>
      <p>
        <TextField
          label="Description"
          type="text"
          value={newQuizDescription}
          onChange={e =>
            setNewQuizDescription(e.target.value)
          }
        />
      </p>
      <Button 
        variant="contained"
        onClick={createQuiz}
      >
        Create
      </Button>
    </>
  )
}

export default Quizzes;