import React from 'react';
import { Outlet, Link, useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

function PageWrapper() {
  const [api, token, setToken, iter, setToast, getToast] = useOutletContext();
  return <>
    <Header />
    <div style={{
      margin: '10px'
    }}>
      <Outlet context={[api, token, setToken, iter, setToast]} />
    </div>
    <Footer />
  </>;
}

export default PageWrapper;