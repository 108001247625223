import React from 'react';
import { Outlet, useNavigate, useParams, Link, useOutletContext } from 'react-router-dom';
import Typography from '@mui/material/Typography';

function Play(props) {

  const [api] = useOutletContext();

  return <>
    <div style={{
      textAlign: 'center',
      marginTop: '50px'
    }}>
      <Typography
        variant="h3"
        sx={{ cursor: 'pointer', mb: 4 }}
      >
        Time to play!
      </Typography>
      <Outlet context={[api]} />
    </div>
  </>;
}

export default Play;