import React from 'react';
import { useNavigate, useParams, Link, useOutletContext } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function PlayMessaging({ playerid }) {

  const navigate = useNavigate();
  const params = useParams();
  const [api] = useOutletContext();
  
  const [newMessage, setNewMessage] = React.useState('');
  const [messages, setMessages] = React.useState([]);
  
  const getMessages = () => {
    api.get(`/v1/player/${playerid}/chat`, {})
    .then(data => {
      setMessages(data.messages.reverse());
    });
  }

  React.useEffect(() => {
    let messageInterval = null;
    if (playerid) {
      messageInterval = setInterval(() => {
        getMessages();
      }, 1000);
    }
    return () => {
      clearInterval(messageInterval);
    }
  }, [playerid]);

  const sendMessage = () => {
    api.post(`/v1/player/${playerid}/chat`, {
      message: {
        messageBody: newMessage,
      }
    })
    .then(data => {
      getMessages();
    }) 
  }

  return (
    <>
      <div style={{ height: '200px', position: 'fixed', bottom: 0, width: '100%', background: 'rgb(233,233,233)' }}>
        <Typography
          variant="h5"
          sx={{ cursor: 'pointer', mt: 3 }}
        >
          Chat
        </Typography>
        <div style={{ height: '80px', overflow: 'scroll' }}>
          {messages.map((messageObj, idx) => {
            const timeSent = (new Date(1000 * messageObj.timeSent));
            return (
              <div key={idx} >
                {messageObj.playerName}: {messageObj.messageBody}
                &nbsp;
                (🕦{timeSent.getMinutes()}:{timeSent.getSeconds()})
              </div>
            );
          })}
        </div>
        <TextField
          type="text"
          value={newMessage}
          onChange={e => setNewMessage(e.target.value)}
          onKeyDown={e => {
            if (e.which === 13) {
              sendMessage();
              setNewMessage('');
            }
          }}
        />
        <Button variant="contained" onClick={sendMessage}  style={{ height: '54px' }}>Send</Button>
      </div>
    </>);
}

export default PlayMessaging;