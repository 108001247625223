import React from 'react';
import { useLocation, useNavigate, useOutletContext, Link, useParams } from 'react-router-dom';

import Back from '../component/Back';
import Title from '../component/Title';
import QuizSessionManager from '../component/QuizSessionManager';
import Button from '@mui/material/Button';

function Quizzes(props) {

  const [api, token, setToken, iter, setToast] = useOutletContext();
  const [quiz, setQuiz] = React.useState(null);
  const [edit, setEdit] = React.useState(false);
  const [inactiveSessions, setInactiveSessions] = React.useState([]);
  const [activeSessions, setActiveSessions] = React.useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const getQuiz = () => {
    api.get(`/v${iter===2?'1':'2'}/admin/quiz/${params.quizid}`, {}, token)
      .then(data => {
        setQuiz(data);
      });
  }

  const getSessions = () => {
    return api.get(`/v1/admin/quiz/${params.quizid}/sessions`, {}, token)
    .then(sessions => {
      setActiveSessions([
        ...(sessions.activeSessions.map(x => ({ id: x, active: true }))),
      ]);
      setInactiveSessions([
        ...(sessions.inactiveSessions.map(x => ({ id: x, active: false }))),
      ]);
    });
  }

  React.useEffect(() => {
    if (token) {
      getQuiz();   
      if (iter === 3) {
        getSessions();
      }
    }
    if (location.pathname.includes('edit')) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [iter, token, location]);

  const editFn = () => {
    if (edit) {
      navigate(`/a/quiz/${params.quizid}`);
    } else {
      navigate(`/a/quiz/${params.quizid}/edit`);
    }
    setEdit(!edit);
  }

  const saveQuizName = () => {
    api.put(`/v${iter===2?'1':'2'}/admin/quiz/${params.quizid}/name`, {
      name: quiz.name,
    }, token)
    .then(() => {
      setToast('Quiz updated', 'success');
    })
  };
  const saveQuizDescription = () => {
    api.put(`/v${iter===2?'1':'2'}/admin/quiz/${params.quizid}/description`, {
      description: quiz.description,
    }, token)
    .then(() => {
      setToast('Quiz updated', 'success');
    })
  };
  const saveQuizThumbnailUrl = () => {
    api.put(`/v1/admin/quiz/${params.quizid}/thumbnail`, {
      imgUrl: quiz.thumbnailUrl,
    }, token)
    .then(() => {
      setToast('Quiz updated', 'success');
    })
  };
  const saveQuizQuestion = (question) => {
    api.put(`/v${iter===2?'1':'2'}/admin/quiz/${params.quizid}/question/${question.questionId}`, {
      questionBody: {
        question: question.question,
        duration: question.duration,
        points: question.points,
        thumbnailUrl: question.thumbnailUrl,
        answers: question.answers,
      }
    }, token)
    .then(() => {
      setToast('Question updated', 'success');
    })
  }

  const addQuestion = () => {
    api.post(`/v${iter===2?'1':'2'}/admin/quiz/${params.quizid}/question`, {
      questionBody: {
        question: 'Default question',
        duration: 5,
        points: 5,
        thumbnailUrl: '',
        answers: [
          {
            answer: 'Example 1',
            correct: true,
          },
          {
            answer: 'Example 2',
            correct: false,
          },
        ],
      }
    }, token)
    .then(() => {
      getQuiz();
    });
  }

  const startSession = () => {
    api.post(`/v1/admin/quiz/${params.quizid}/session/start`, {
      autoStartNum: 10,
    }, token)
    .then(() => {
      getSessions();
    });
  }

  return (
    <>
      {quiz && (
        <>
          <Title><Back to="/a/quizzes" />{quiz.name}</Title>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <div style={{ flex: 1, minWidth: '300px', margin: '10px 0' }}>
              <div
                style={{
                  height: '300px',
                  background: iter === 2 ? 'none' : `url(${quiz.thumbnailUrl})`,
                  backgroundSize: '100% 100%',
                }}
              > 
                <div
                  style={{
                    padding: '10px',
                    height: '60px',
                    background: 'rgba(255,255,255,0.4)',
                    width: '100%',
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      cursor: 'pointer',
                      float: 'right',
                    }}
                    onClick={() => 
                      navigate(`/a/quiz/${params.quizid}/edit`)
                    }
                  >
                    Edit
                  </Button>
                  <h1 style={{ marginTop: '-3px'}}>{quiz.name}</h1>
                </div>
              </div>
              <p style={{ margin: '10px'}}>Description: {quiz.description}</p>
            </div>
            {iter === 3 && <div style={{ flex: 2, width: '600px' }}> 
              <h2>Sessions</h2>
              <Button
                variant="contained"
                style={{
                  cursor: 'pointer'
                }}
                onClick={startSession}
              >
                Create New Session
              </Button>
              <br />
              <br />
              {activeSessions.length > 0 || inactiveSessions.length > 0 ? (
                <>
                  {activeSessions.length > 0 && (
                    <div style={{ }}>
                      {activeSessions.map((as, idx2) => (
                        <div key={idx2} style={{
                            border: '1px solid #000',
                            padding: '10px',
                            borderRadius: '5px',
                            minWidth: '100px',
                            marginBottom: '10px'
                        }}>
                          <QuizSessionManager
                            quizid={params.quizid}
                            getSessions={getSessions}
                            sessionid={as.id}
                            active={as.active}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {inactiveSessions.length > 0 && (
                    <div style={{ }}>
                      {inactiveSessions.map((as, idx2) => (
                        <div key={idx2} style={{
                            border: '1px solid #000',
                            padding: '10px',
                            borderRadius: '5px',
                            minWidth: '100px',
                            marginBottom: '10px',
                        }}>
                          <QuizSessionManager
                            quizid={params.quizid}
                            getSessions={getSessions}
                            sessionid={as.id}
                            active={as.active}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <>No sessions</>
              )}
            </div>}
          </div>
        </>
      )}
    </>
  )
}

export default Quizzes;