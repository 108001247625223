import React from 'react';
import { useParams, useNavigate, useOutletContext, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Title from '../component/Title';
import Back from '../component/Back';

function Profile(props) {

  const [api, token, setToken, iter] = useOutletContext();
  const navigate = useNavigate();
  const pageType = useParams().type;

  const [email, setEmail] = React.useState('');
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [nameFirst, setNameFirst] = React.useState('');
  const [nameLast, setNameLast] = React.useState('');

  React.useEffect(() => {
    if (token) {
      api.get(`/v${iter===2?'1':'2'}/admin/user/details`, {}, token)
      .then(data => {
        setEmail(data.user.email);
      });
    }
  }, [token]);

  const updateDetails = () => {
    const field = pageType === 'password' ? 'password' : 'details';
    const obj = field === 'password'
      ? { oldPassword, newPassword }
      : { email, nameFirst, nameLast };
    
    api.put(`/v${iter===2?'1':'2'}/admin/user/${field}`, obj, token)
    .then(() => navigate('/a/profile'));
  }

  return (
    <>
      <Title><Back to="/a/profile" /> Edit Profile</Title>
      {['email', 'name'].includes(pageType) ? (
        <>
          <p>
            <TextField
              variant="outlined"
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="text"
              label="Email"
            />
          </p>
          <p>
            <TextField
              variant="outlined"
              value={nameFirst}
              onChange={e => setNameFirst(e.target.value)}
              type="text"
              label="First Name"
            />
            <TextField
              variant="outlined"
              value={nameLast}
              onChange={e => setNameLast(e.target.value)}
              type="text"
              label="Last Name"
            />
          </p>
        </>
      ) : (
        <>
          <p>
            Old Password: &nbsp;
            <TextField
              variant="outlined"
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
              type="password"
            />
          </p>
          <p>
            New Password: &nbsp;
            <TextField
              variant="outlined"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              type="password"
            />
          </p>
        </>
      )}
      <Button variant="contained" onClick={updateDetails}>Save</Button>
    </>
  )
}

export default Profile;