export const hydrateQuizzes = (iter, api, url, token, callback, errorCallback) => {
  api.get(url, {}, token)
    .catch((err) => {
      errorCallback();
    })
    .then(data => {
      Promise.all(data.quizzes.map(quiz => {
        return api.get(`/v${iter===2?'1':'2'}/admin/quiz/${quiz.quizId}`, {}, token)
      })).then(done => {
        const newData = data.quizzes.map((quiz, idx) => ({
          ...quiz,
          ...done[idx],
        }));
        callback(newData);
      });
    });
}