const HeroTextFill = ({ children }) => {
  return (
    <div style={{
      textAlign: 'center',
      margin: '50px 0',
      fontSize: '1.5em',
    }}>
      {children}
    </div>
  )
}

export default HeroTextFill;