import React from 'react';
import { useNavigate, useOutletContext, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Title from '../component/Title';

function Login(props) {

  const [api, token, setToken, iter] = useOutletContext();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('hayden@unsw.edu.au');
  const [password, setPassword] = React.useState('1234567899(a');

  React.useEffect(() => {
    if (token) {
        navigate('/a/quizzes');
    }
  }, [token]);

  const login = async () => {
    api.post(`/v1/admin/auth/login`, {
      email,
      password,
    }).then(data => {
      localStorage.setItem('token', data.token);
      setToken(data.token);
      navigate('/a/quizzes');
    });
  }

  return (<>
    <Title>Login</Title>
    Haven't registered yet? <Link to="/a/register">Register now</Link>.
    <br />
    <br />
    <br />
    <TextField
      label="Email"
      value={email} 
      onChange={e =>
        setEmail(e.target.value)
      }
      type="text"
      sx={{
        width: '300px',
      }}
    /><br /><br />
    <TextField
      label="Password"
      value={password} 
      onChange={e =>
        setPassword(e.target.value)
      }
      type="password"
      sx={{
        width: '300px',
      }}
    /><br /><br />
    <Button
      variant="contained"
      onClick={login}
    >
      Login
    </Button>
  </>);
}

export default Login;