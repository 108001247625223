import React from 'react';
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import { get, post, put, del } from '../util/api';

import AlertBar from './AlertBar';

function WholeSite() {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = React.useState(null);
  const [iter, setIter] = React.useState(2);
  const [text, setText] = React.useState(null);
  const [sev, setSev] = React.useState(null);

  React.useEffect(() => {
    setToken(localStorage.getItem('token'));
    if (location.pathname === '/') {
      if (token) {
        navigate('/a/quizzes');
      } else {
        navigate('/a/login');
      }
    }
  }, []);

  const setToast = (text, type) => {
    if (text == null) {
      setText(null);
      setSev(null);
    } else {
      setText(text);
      setSev(type);
    }
  };
  
  const getToast = () => {
    return { text, sev };
  };

  const handleError = (err) => {
    if (err.indexOf('Invalid token') !== -1) {
      navigate('/a/login');
    }
    setToast(err, 'error');
  };

  const api = {
    get: (path, data, token) => {
      return new Promise((resolve, reject) => {
        get(path, data, token, iter)
        .then(data => resolve(data))
        .catch(handleError);
      })
    },
    put: (path, data, token) => {
      return new Promise((resolve, reject) => {
        put(path, data, token, iter)
        .then(data => resolve(data))
        .catch(handleError);
      })
    },
    post: (path, data, token) => {
      return new Promise((resolve, reject) => {
        post(path, data, token, iter)
        .then(data => resolve(data))
        .catch(handleError);
      })
    },
    del: (path, data, token) => {
      return new Promise((resolve, reject) => {
        del(path, data, token, iter)
        .then(data => resolve(data))
        .catch(handleError);
      })
    }
  }
  
  return <>
    <Outlet context={[api, token, setToken, iter, setToast, getToast, setIter]} />
    <AlertBar setToast={setToast} getToast={getToast} />
  </>;
}

export default WholeSite;