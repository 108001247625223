import React from 'react';
import { useNavigate, useOutletContext, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Title from '../component/Title';

function Profile(props) {

  const [api, token, setToken, iter] = useOutletContext();
  const navigate = useNavigate();

  const [profileDetails, setProfileDetails] = React.useState(null);

  React.useEffect(() => {
    if (token) {
      api.get(`/v${iter===2?'1':'2'}/admin/user/details`, {}, token)
      .then(data => {
        setProfileDetails(data.user);
      });
    }
  }, [token]);

  const P = ({ children }) => {
    return <Typography
      variant="p"
      sx={{
        display: 'block',
        margin: '10px auto'
      }}>
        {children}
      </Typography>
  }

  const H = ({ children }) => {
    return (
      <Typography
        variant="h5"
        sx={{
          marginTop: '20px',
          marginBottom: '10px',
        }}
      >
        {children}
      </Typography>
    );
  }

  return (
    <>
      <Title>Profile</Title>
      {profileDetails ? (
        <>
          <H>Your Details</H>
          <P>
            📧 <b>Email</b>: {profileDetails.email}
            &nbsp;&nbsp;
            <Button
              variant="contained"
              style={{
                cursor: 'pointer'
              }}
              onClick={() => 
                navigate('/a/profile/edit/email')
              }
            >
              edit
            </Button>
          </P>
          <P>
            📣 <b>Name</b>: {profileDetails.name}
            &nbsp;&nbsp;
            <Button
              variant="contained"
              style={{
                cursor: 'pointer'
              }}
              onClick={() => 
                navigate('/a/profile/edit/name')
              }>
                edit
              </Button>
          </P>
          
          <H>Your Stats</H>
          <P>⛔️ <b>Failed Passwords since last login</b>: {profileDetails.numFailedPasswordsSinceLastLogin}</P>
          <P>🎉 <b>Number of successful logins ever</b>: {profileDetails.numSuccessfulLogins}</P>

          <H variant="h5" gutterBottom>Security</H>
          <Button variant="contained" onClick={() => navigate("/a/profile/edit/password")}>Change Password</Button>
        </>
      ) : (
        <>Loading...</>
      )
    }
    </>
  )
}

export default Profile;