import React from 'react';
import { useOutletContext } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import MuiAlert from '@mui/material/Alert';

export default function AlertBar({ setToast, getToast }) {
  const [open, setOpen] = React.useState(false);
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast(null);
  };

  const Alert = React.forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const toast = getToast();

  return (
    <div>
      <Snackbar
        open={toast.text !== null}
        autoHideDuration={6000}
        onClose={handleClose}
        sx={{
          bottom: '55px',
        }}
      >
        {toast.text && <Alert
          onClose={handleClose}
          severity={toast.sev}
          sx={{
            width: '100%',
          }}
        >
          {toast.text}
        </Alert>}
      </Snackbar>
    </div>
  );
}