import { useNavigate } from 'react-router-dom';

const Back = ({ to }) => {
  const navigate = useNavigate();
  return (
    <span
      onClick={() => navigate(to)}
      style={{ cursor: 'pointer' }}
    >
      ⬅️&nbsp;
    </span>
  )
}

export default Back;