import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreFromTrash from '@mui/icons-material/RestoreFromTrash';
import Face from '@mui/icons-material/Face';
import ViewList from '@mui/icons-material/ViewList';
import Create from '@mui/icons-material/Create';
import Paper from '@mui/material/Paper';

import AlertBar from './AlertBar';

export default function FixedBottomNavigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(
    location.pathname === '/a/quizzes' ? 0 :
    location.pathname === '/a/quizzes/create' ? 1 :
    location.pathname === '/a/quizzes/trash' ? 2 :
    0
  );

  if (['/a/login', '/a/register'].includes(location.pathname)) {
    return <></>;
  }
  
  return (
    <Box sx={{ pb: 7 }}>
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="Quiz List"
            icon={<ViewList />}
            onClick={() => navigate('/a/quizzes')}
          />
          <BottomNavigationAction
            label="New Quiz"
            icon={<Create />}
            onClick={() => navigate('/a/quizzes/create')}
          />
          <BottomNavigationAction
            label="Trash Bin"
            icon={<RestoreFromTrash />}
            onClick={() => navigate('/a/quizzes/trash')}
          />
          <BottomNavigationAction
            label="Profile"
            icon={<Face />}
            onClick={() => navigate('/a/profile')}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}




