import Typography from '@mui/material/Typography';

const Title = ({ children }) => {
  return (
    <Typography
      variant="h3"
      gutterBottom
      sx={{
        marginTop: '25px',
        fontSize: '1.8em'
      }}
    >
      {children}
    </Typography>
  )
}

export default Title;