import React from 'react';
import { useNavigate, useParams, Link, useOutletContext } from 'react-router-dom';

import PlayMessaging from '../component/PlayMessaging';
import Tag from '../component/Tag';
import FinalResults from '../component/FinalResults';
import Checkbox from '@mui/material/Checkbox';

let countdownTimer = null;

function PlayActive(props) {

  const navigate = useNavigate();
  const params = useParams();
  const [api] = useOutletContext();
  
  const [selectedAnswers, setSelectedAnswers] = React.useState([]);
  const [questionStartTime, setQuestionStartTime] = React.useState(null);
  const [currentQuestionInfo, setCurrentQuestionInfo] = React.useState(null);
  const [currentResultsInfo, setCurrentResultsInfo] = React.useState(null);
  const [finalResultsInfo, setFinalResultsInfo] = React.useState(null);
  const [timeElapsed, setTimeElapsed] = React.useState(0);
  const [status, setStatus] = React.useState(null);
  const playerid = params.playerid;

  const getStatus = () => {
    api.get(`/v1/player/${playerid}`, {})
    .then(data => {
      setStatus((prevState) => {
        if (!prevState || data.state !== prevState.state) {
          return data;
        }
        return prevState;
      });
    });
  }

  React.useEffect(() => {
    let statusInterval = null;
    if (playerid) {
      statusInterval = setInterval(() => {
        getStatus();
      }, 1000);
    }
    return () => {
      clearInterval(statusInterval);
    }
  }, [playerid]);

  React.useEffect(() => {
    clearInterval(countdownTimer);
    setTimeElapsed(0);
    if (status) {
      if (status.state === 'QUESTION_OPEN') {
        api.get(`/v1/player/${playerid}/question/${status.currentQuestionPosition}`)
        .then(data => {
          setCurrentQuestionInfo(data);
          const startTime = new Date();
          setQuestionStartTime(startTime);
          countdownTimer = setInterval(() => {
            setTimeElapsed(Math.abs((new Date()).getTime() - startTime.getTime()) / 1000);
          }, 1000);
        });
      }
      if (status.state === 'QUESTION_CLOSE') {
        setSelectedAnswers([]);
      }
      if (status.state === 'ANSWER_SHOW') {
        setSelectedAnswers([]);
        api.get(`/v1/player/${playerid}/question/${status.currentQuestionPosition}/results`)
        .then(data => {
          setCurrentResultsInfo(data);
        });
      }
      if (status.state === 'FINAL_RESULTS') {
        api.get(`/v1/player/${playerid}/results`)
        .then(data => {
          setFinalResultsInfo(data);
        });
      }
    }
  }, [status])

  const resetGame = () => {
    setStatus(null);
    navigate('/play');
  }

  const changeAnswer = (id, checked) => {
    const newAnswers = [...selectedAnswers];
    if (checked) {
      if (!newAnswers.includes(id)) {
        newAnswers.push(id);
      }
    } else {
      const idx = newAnswers.indexOf(id);
      newAnswers.splice(idx, 1);
    }
    setSelectedAnswers(newAnswers)
    api.put(`/v1/player/${playerid}/question/${status.currentQuestionPosition}/answer`, {
      answerIds: newAnswers,
    });
  };

  return (
    <>
      {(playerid) && (
        <>
          {!status ? (
            <>
              Loading...
            </>
          ) : status.state === 'LOBBY' ? (
            <>
              Please wait for the game to begin
              <br /><br />
              ⏰⏰⏰⏰⏰
            </>
          ) : status.state === 'QUESTION_COUNTDOWN' ? (
            <>
              Get ready...! <br />
              Question incoming.
            </>
          ) : status.state === 'QUESTION_OPEN' ? (
            <>
              Answer this question!
              {currentQuestionInfo && (
                <>
                  <h3>{currentQuestionInfo.question}</h3>
                  <p>Points: {currentQuestionInfo.points}</p>
                  <p>Duration: {currentQuestionInfo.duration} seconds</p>
                  <p>Time remaining: {Math.round(currentQuestionInfo.duration - timeElapsed, 1)} seconds</p>
                  {currentQuestionInfo.answers.map((answer, idx) => (
                    <div
                      key={idx}
                      style={{ maxWidth: '400px', margin: '0 auto', padding: '5px', border: '1px solid #000', cursor: 'pointer'}}
                      onClick={() => changeAnswer(answer.answerId, !selectedAnswers.includes(answer.answerId))}
                    >
                      {answer.answer}
                      <Checkbox
                        onChange={e => changeAnswer(answer.answerId, e.target.checked)}
                        checked={selectedAnswers.includes(answer.answerId)}
                      />
                    </div>
                  ))}
                </>
              )}
            </>
          ) : status.state === 'QUESTION_CLOSE' ? (
            <>
              Great job. Standby for answers.
            </>
          ) : status.state === 'ANSWER_SHOW' ? (
            <>
              Here are the answers! <br />
              <br />
              {currentResultsInfo && (
                <>
                  Average Answer Time: {currentResultsInfo.averageAnswerTime}<br />
                  % correct: {currentResultsInfo.percentCorrect}<br />

                  {/*<b>{currentResultsInfo.playersCorrect.join(', ')}</b>*/}
                </>
              )}
              <br />
              Please wait for the next question
            </>
          ) : status.state === 'FINAL_RESULTS' ? (
            <>
              {finalResultsInfo && (
                <>
                  <FinalResults data={finalResultsInfo} />
                </>
              )}
            </>
          ) : (
            <>
              Game is over...<br />
              <br />
              <button onClick={resetGame}>Play new game?</button>
            </>
          )}
        </>
      )}
      <PlayMessaging playerid={playerid} />
    </>);
}

export default PlayActive;