const Tag = ({ colour, children }) => {
  return (
    <div style={{
      display: 'inline-block',
      backgroundColor: colour,
      padding: '3px 10px',
      borderRadius: '2px',
      margin: '5px',
    }}>
      {children}
    </div>
  )
};

export default Tag;