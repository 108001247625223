import React from 'react';
import { useNavigate, useParams, Link, useOutletContext } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function PlayLobby(props) {

  const navigate = useNavigate();
  const params = useParams();

  const [api, token, setToken] = useOutletContext();
  const [sessionId, setSessionId] = React.useState('');
  const [hideSession, setHideSession] = React.useState(true);
  const [name, setName] = React.useState('');

  React.useEffect(() => {
    setSessionId(params.sessionid);
    if (!params.sessionid) {
      setHideSession(false);
    }
  }, [params]);

  const start = () => {
    api.post(`/v1/player/join`, {
      sessionId: parseInt(sessionId),
      name,
    })
    .then(data => {
      navigate(`/play/${data.playerId}`)
    })
  }

  return (
    <div>
      {!hideSession && (
        <>
          <TextField
            variant="outlined"
            label={"Session ID"}
            value={sessionId}
            onChange={e => 
              setSessionId(e.target.value)
            }
            type="text"
          />
          <br />
          <br />
        </>
      )}
      <TextField
        variant="outlined"
        label={"Name"}
        value={name}
        onChange={e => 
          setName(e.target.value)
        }
        type="text"
      />
      <br /><br />
      <Button
        variant="contained"
        color="primary"
        onClick={start}
        sx={{ marginTop: '6px' }}
      >
        Join
      </Button>
    </div>
  );
}

export default PlayLobby;