import React from 'react';
import { useOutletContext } from 'react-router-dom';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FinalResults from './FinalResults';

import config from '../config';
import Tag from './Tag';

const QuizSessionManager = ({ getSessions, quizid, sessionid, active }) => {
  const [api, token, setToken, iter] = useOutletContext();
  const [sessionData, setSessionData] = React.useState(null);
  const [results, setResults] = React.useState(null);
  const [resultsCsvUrl, setResultsCsvUrl] = React.useState(null);
  
  const getSessionStatus = () => {
    api.get(`/v1/admin/quiz/${quizid}/session/${sessionid}`, {}, token)
      .then(data => {
        setSessionData(data);
        if (data.state === 'FINAL_RESULTS') {
          api.get(`/v1/admin/quiz/${quizid}/session/${sessionid}/results`, {}, token)
          .then(resultsContent => {
            setResults(resultsContent);
          })
          api.get(`/v1/admin/quiz/${quizid}/session/${sessionid}/results/csv`, {}, token)
          .then(resultsContent => {
            setResultsCsvUrl(resultsContent.url);
          })
        }
      });
  }

  React.useEffect(() => {
    getSessionStatus();
    if (active) {
      const checkStatus = setInterval(() => {
        getSessionStatus();
      }, 1000);
      return () => {
        clearInterval(checkStatus);
      }
    }
  }, []);


  const sessionMutate = (sessionid, action) => {
    api.put(`/v1/admin/quiz/${quizid}/session/${sessionid}`, {
      action: action,
    }, token)
    .then(getSessions)
    .then(getSessionStatus)
  };

  return sessionData && (
    <>
      <Tag colour="rgb(220,220,245)">{sessionid}</Tag>
      <Tag colour={active && sessionData.state !== 'END' ? 'rgb(220,245,220)' : 'rgb(245,220,220)'}>{active && sessionData.state !== 'END' ? 'Active' : 'Inactive'}</Tag>
      <Tag colour="rgb(245,220,245)">{sessionData.state}</Tag>
      <br />
      {sessionData.state === 'LOBBY' && (
        <a
          target="_blank"
          href={`${config.baseurl}/play/join/${sessionid}`}
        >
          Get Join Game Link
        </a>

      )}
      <br />
      {active && (
        <>
          {!['QUESTION_COUNTDOWN', 'QUESTION_OPEN', 'END', 'FINAL_RESULTS'].includes(sessionData.state) && (
            <>
              <Button
                variant="contained"
                color="success"
                onClick={() => 
                  sessionMutate(sessionid, 'NEXT_QUESTION')}
              >
                Next Q
              </Button>&nbsp;
            </>
          )}
          {!['ANSWER_SHOW', 'END', 'LOBBY', 'FINAL_RESULTS'].includes(sessionData.state) && (
            <>
              <Button
                variant="contained"
                color="success"
                onClick={() => 
                  sessionMutate(sessionid, 'GO_TO_ANSWER')}
              >
                Answer →
              </Button>&nbsp;
            </>
          )}
          {!['END', 'QUESTION_OPEN', 'LOBBY', 'FINAL_RESULTS'].includes(sessionData.state) && (
            <>
              <Button
                variant="contained"
                color="success"
                onClick={() => 
                  sessionMutate(sessionid, 'GO_TO_FINAL_RESULTS')}
              >
                Final Results →
              </Button>&nbsp;
            </>
          )}
          {!['END'].includes(sessionData.state) && (
            <Button
              variant="contained"
              color="success"
              onClick={() => 
                sessionMutate(sessionid, 'END')}
            >
              End →
            </Button>
          )}
        </>
      )}
      {results && sessionData.state === 'FINAL_RESULTS' && (
        <>
          <br />
          <FinalResults data={results} /><br />
          <b>CSV</b>: <a target="_blank" href={resultsCsvUrl}>{resultsCsvUrl}</a>
        </>
      )}
    </>
  )
};

export default QuizSessionManager;