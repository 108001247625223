import axios from 'axios';
import config from '../config';

const baseurl = config.serverbaseurl;

export const get = (path, data, token, iter) => {
  return new Promise((resolve, reject) => {
    let opts = {};
    if (iter === 3) {
      opts.headers = {
        token,
      };
    } else {
      if (path.includes('?')) {
        path += `&token=${token}`;
      } else {
        path += `?token=${token}`;
      }
    }
    opts = {
      ...opts,
      method: 'get',
      url: `${baseurl}${path}`,
    };
    axios(opts)
    .then(response => {
      resolve(response.data);
    })
    .catch(error => {
      reject(error.response.data.error);
    });
  });
}

export const post = (path, data, token, iter) => {
  return new Promise((resolve, reject) => {
    let opts = {};
    if (iter === 3) {
      opts.headers = {
        token,
      };
    } else {
      data.token = token;
    }
    opts = {
      ...opts,
      method: 'post',
      data: data,
      url: `${baseurl}${path}`,
    };
    axios(opts)
    .then(response => {
      resolve(response.data);
    })
    .catch(error => {
      reject(error.response.data.error);
    });
  });
}

export const del = (path, data, token, iter) => {
  return new Promise((resolve, reject) => {
    let opts = {};
    if (iter === 3) {
      opts.headers = {
        token,
      };
    } else {
      if (path.includes('?')) {
        path += `&token=${token}`;
      } else {
        path += `?token=${token}`;
      }
    }
    opts = {
      ...opts,
      method: 'delete',
      url: `${baseurl}${path}`,
    };
    axios(opts)
    .then(response => {
      resolve(response.data);
    })
    .catch(error => {
      reject(error.response.data.error);
    });
  });
}

export const put = (path, data, token, iter) => {
  return new Promise((resolve, reject) => {
    let opts = {};
    if (iter === 3) {
      opts.headers = {
        token,
      };
    } else {
      data.token = token;
    }
    opts = {
      ...opts,
      method: 'put',
      data: data,
      url: `${baseurl}${path}`,
    };
    axios(opts)
    .then(response => {
      resolve(response.data);
    })
    .catch(error => {
      reject(error.response.data.error);
    });
  });
}
