import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';
import Login from './page/Login';
import Register from './page/Register';
import Quizzes from './page/Quizzes';
import QuizzesCreate from './page/QuizzesCreate';
import QuizzesTrash from './page/QuizzesTrash';
import Profile from './page/Profile';
import ProfileEdit from './page/ProfileEdit';
import QuizSingle from './page/QuizSingle';
import QuizSingleEdit from './page/QuizSingleEdit';
import Play from './page/Play';
import PlayLobby from './page/PlayLobby';
import PlayActive from './page/PlayActive';
import LoginWrapper from './component/LoginWrapper';
import WholeSite from './component/WholeSite';
import LoggedInSections from './component/LoggedInSections';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<WholeSite />}>
          <Route path="/play" element={<Play />}>
            <Route path="/play" element={<PlayLobby />} />
            <Route path="/play/join/:sessionid" element={<PlayLobby />} />
            <Route path="/play/:playerid" element={<PlayActive />} />
          </Route>
          <Route path="/a" element={<LoginWrapper />}>
            <Route path="/a/login" element={<Login />} />
            <Route path="/a/register" element={<Register />} />
            <Route path="/a" element={<LoggedInSections />}>
              <Route path="/a/quizzes" element={<Quizzes />} />
              <Route path="/a/quizzes/create" element={<QuizzesCreate />} />
              <Route path="/a/quizzes/trash" element={<QuizzesTrash />} />
              <Route path="/a/profile" element={<Profile />} />
              <Route path="/a/profile/edit/:type" element={<ProfileEdit />} />
              <Route path="/a/quiz/:quizid" element={<QuizSingle />} />
              <Route path="/a/quiz/:quizid/edit" element={<QuizSingleEdit />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
