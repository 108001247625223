import React from 'react';
import { useNavigate, useOutletContext, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import QuizCard from '../component/QuizCard';
import { hydrateQuizzes } from '../util/helpers';
import Title from '../component/Title';

function Quizzes(props) {

  const navigate = useNavigate();
  const [api, token, setToken, iter, setToast] = useOutletContext();
  const [quizzes, setQuizzes] = React.useState(null);
  const [newQuizName, setNewQuizName] = React.useState('');
  const [newQuizDescription, setNewQuizDescription] = React.useState('');

  const getQuizzes = () => {
    hydrateQuizzes(iter, api, `/v${iter===2?'1':'2'}/admin/quiz/list`, token, setQuizzes, () => {
      localStorage.removeItem('token');
      setToken(null);
      navigate('/a/login');
    });
  }

  React.useEffect(() => {
    if (token) {
      getQuizzes();   
    }
  }, [token]);

  const sendToTrash = (id) => {
    const conf = window.confirm('Are you sure you want to send this quiz to trash?');
    if (conf) {
      api.del(`/v${iter===2?'1':'2'}/admin/quiz/${id}`, {}, token)
      .then(() => {
        setToast('Successfully sent to trash', 'success')
        getQuizzes();
      });
    }
  }

  const transferQuiz = (id) => {
    const prompt = window.prompt('Enter user email to transfer to?');
    if (prompt) {
      api.post(`/v${iter===2?'1':'2'}/admin/quiz/${id}/transfer`, {
        userEmail: prompt,
      }, token)
      .then(() => {
        setToast(`Quiz successfully transferred to ${prompt}`, 'success');
        getQuizzes();
      })
    }
  };

  return (
    <>
      <Title>List of Active Quizzes</Title>
      {quizzes ? (      
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '20px',
          marginTop: '20px'
        }}>
          {quizzes.map((quiz, idx) => {
            return (
              <QuizCard
                iter={iter}
                quiz={quiz}
                onClick={() => {
                  navigate(`/a/quiz/${quiz.quizId}`);
                }}
                key={idx}
              >
                <Button
                  sx={{ fontSize: '1.6em' }}
                  title="Delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    sendToTrash(quiz.quizId);
                  }}
                >
                  🗑
                </Button>
                <Button
                  sx={{ fontSize: '1.6em' }}
                  title="Transfer"
                  onClick={(e) => {
                    e.stopPropagation();
                    transferQuiz(quiz.quizId);
                  }}
                >
                  📤
                </Button>
              </QuizCard>
            );
          })}
        </div>
      ) : (
        <>Loading...</>
      )}
    </>
  )
}

export default Quizzes;