import React from 'react';
import { useNavigate, useOutletContext, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

import QuizCard from '../component/QuizCard';
import HeroTextFill from '../component/HeroTextFill';
import Title from '../component/Title';

function QuizzesTrash(props) {

  const [api, token, setToken, iter, setToast] = useOutletContext();
  const [deleteMode, setDeleteMode] = React.useState(false);
  const [emptyStaged, setEmptyStaged] = React.useState([]);
  const [quizzes, setQuizzes] = React.useState(null);
  const navigate = useNavigate();

  const getQuizzes = () => {
    api.get(`/v${iter===2?'1':'2'}/admin/quiz/trash`, {}, token)
    .then(data => {
      setQuizzes(data.quizzes);
    })
  }

  React.useEffect(() => {
    if (token) {
      getQuizzes();   
    }
  }, [token]);

  const restoreFromTrash = (id) => {
    const conf = window.confirm('Are you sure you want to restore this quiz?');
    if (conf) {
      api.post(`/v${iter===2?'1':'2'}/admin/quiz/${id}/restore`, {}, token)
      .then(() => {
        setToast('Restored from trash', 'success');
        getQuizzes();
      });
    }
  }

  const emptyTrash = () => {
    const quizIds = `[${emptyStaged.join(',')}]`;
    api.del(`/v${iter===2?'1':'2'}/admin/quiz/trash/empty?quizIds=${quizIds}`, {}, token)
      .then(() => {
        setToast('Emptied trash', 'success');
        getQuizzes();
      });
  }

  const toggleItem = (quiz) => {
    const newEmptyStaged = [...emptyStaged];
    if (!emptyStaged.includes(quiz.quizId)) {
      if (!newEmptyStaged.includes(quiz.quizId)) {
        newEmptyStaged.push(quiz.quizId);
      }
    } else {
      const index = newEmptyStaged.indexOf(quiz.quizId);
      newEmptyStaged.splice(index, 1);
    }
    setEmptyStaged(newEmptyStaged);
  }

  return (
    <>
      <Title>Trash</Title>
      {!quizzes ? (
        <>Loading...</>
      ) : quizzes.length > 0 ? (
        <> 
          <Button variant="contained" onClick={() => {
            setEmptyStaged([]);
            setDeleteMode(!deleteMode)
          }}>{deleteMode ? 'Back' : 'Empty trash'}</Button>
          {emptyStaged.length > 0 && deleteMode &&
            <>
              &nbsp;
              <Button variant="contained" onClick={() => emptyTrash()}>Permanently Remove Selected</Button>
            </>
          }
          <br /><br />
          <div style={{ display: 'flex' }}>
            {quizzes.map((quiz, idx) => {
              return (
                <div key={idx}>
                  <QuizCard
                    quiz={quiz}
                    sx={{
                      background: emptyStaged.includes(quiz.quizId) ? 'rgb(240,240,255)' : 'default',
                    }}
                    onClick={() => {
                      if (deleteMode) {
                        toggleItem(quiz)
                      }
                    }}
                    blockCursor={!deleteMode}
                  >
                    <div style={{
                      textAlign: 'center',
                      margin: '0 auto',
                    }}>
                      {deleteMode ? (
                        <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                          type="checkbox"
                          checked={emptyStaged.includes(quiz.quizId)}
                          
                        />
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() => restoreFromTrash(quiz.quizId)}
                        >
                          Restore
                        </Button>
                      )}
                    </div>
                  </QuizCard>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <HeroTextFill>🧼 Squeaky clean! No items in trash.<br />
        <Link to="/a/quizzes">⬅️ Return</Link></HeroTextFill>
      )}
    </>
  )
}

export default QuizzesTrash;