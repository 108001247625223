import React from 'react';
import { useLocation, useNavigate, useOutletContext, Link, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Back from '../component/Back';
import Title from '../component/Title';
import QuizSessionManager from '../component/QuizSessionManager';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import ContentCopy from '@mui/icons-material/ContentCopy';
import Delete from '@mui/icons-material/Delete';
import Done from '@mui/icons-material/Done';
import Close from '@mui/icons-material/Close';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowBack from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';

const TextButton = ({ title, value, onChange, onClick }) => {
  return (
    <>
      <TextField
        variant="outlined"
        value={value}
        onChange={onChange}
        type="text"
        label={title}
      />&nbsp;
      <Button
        variant="contained"
        color="secondary"
        onClick={onClick}
        sx={{ marginTop: '6px' }}
      >
        Save
      </Button>
    </>
  );
}

function QuizSingleEdit(props) {

  const [api, token, setToken, iter, setToast] = useOutletContext();
  const [quiz, setQuiz] = React.useState(null);
  const [activeSessions, setActiveSessions] = React.useState([]);
  const [inactiveSessions, setInactiveSessions] = React.useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const getQuiz = () => {
    api.get(`/v${iter===2?'1':'2'}/admin/quiz/${params.quizid}`, {}, token)
      .then(data => {
        setQuiz(data);
      });
  }

  const getSessions = () => {
    return api.get(`/v1/admin/quiz/${params.quizid}/sessions`, {}, token)
    .then(sessions => {
      setActiveSessions(sessions.activeSessions);
      setInactiveSessions(sessions.inactiveSessions);
    });
  }

  React.useEffect(() => {
    if (token) {
      getQuiz();
      if (iter === 3) {
        getSessions();
      }
    }
  }, [token, location]);

  const saveQuizName = () => {
    api.put(`/v${iter===2?'1':'2'}/admin/quiz/${params.quizid}/name`, {
      name: quiz.name,
    }, token)
    .then(() => {
      setToast('Quiz updated', 'success');
    })
  };
  const saveQuizDescription = () => {
    api.put(`/v${iter===2?'1':'2'}/admin/quiz/${params.quizid}/description`, {
      description: quiz.description,
    }, token)
    .then(() => {
      setToast('Quiz updated', 'success');
    })
  };
  const saveQuizThumbnailUrl = () => {
    api.put(`/v1/admin/quiz/${params.quizid}/thumbnail`, {
      imgUrl: quiz.thumbnailUrl,
    }, token)
    .then(() => {
      setToast('Quiz updated', 'success');
    })
  };
  const saveQuizQuestion = (idx) => {
    api.put(`/v${iter===2?'1':'2'}/admin/quiz/${params.quizid}/question/${quiz.questions[idx].questionId}`, {
      questionBody: {
        question: quiz.questions[idx].question,
        duration: quiz.questions[idx].duration,
        points: quiz.questions[idx].points,
        thumbnailUrl: quiz.questions[idx].thumbnailUrl,
        answers: quiz.questions[idx].answers,
      }
    }, token)
    .then(() => {
      const newQuiz = {...quiz};
      newQuiz.questions[idx] = {
        ...newQuiz.questions[idx],
        modified: false,
      }
      setQuiz(newQuiz);
      setToast('Question updated', 'success');
    })
  }

  const addQuestion = () => {
    api.post(`/v${iter===2?'1':'2'}/admin/quiz/${params.quizid}/question`, {
      questionBody: {
        question: 'Default question',
        duration: 5,
        points: 5,
        answers: [
          {
            answer: 'Example 1',
            correct: true,
          },
          {
            answer: 'Example 2',
            correct: false,
          },
        ],
        thumbnailUrl: iter === 2 ? null : 'https://www.unsw.edu.au/content/dam/images/photos/events/open-day/2020-12-homepage-update/OpenDay_2019_campaign%20-0307-crop.cropimg.width=1920.crop=square.jpg',
      }
    }, token)
    .then(() => {
      getQuiz();
    });
  }

  return (
    <>
      {quiz && (
        <>
          <Title><Back to={`/a/quiz/${params.quizid}`} />{quiz.name}</Title>
          <TextButton
            title={'Title'}
            value={quiz.name}
            onChange={(e) => {
              const newQuiz = {...quiz};
              newQuiz.name = e.target.value;
              setQuiz(newQuiz);
            }}
            onClick={() => saveQuizName()}
          /><br /><br />
          <TextButton
            title={'Description'}
            value={quiz.description}
            onChange={(e) => {
              const newQuiz = {...quiz};
              newQuiz.description = e.target.value;
              setQuiz(newQuiz);
            }}
            onClick={() => saveQuizDescription()}
          /><br /><br />
          <TextButton
            title={'Thumbnail URL'}
            value={quiz.thumbnailUrl}
            onChange={(e) => {
              const newQuiz = {...quiz};
              newQuiz.thumbnailUrl = e.target.value;
              setQuiz(newQuiz);
            }}
            onClick={() => saveQuizThumbnailUrl()}
          />
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {quiz.questions.map((question, idx) => {
              const updateField = (obj) => {
                const newQuiz = {...quiz};
                newQuiz.questions[idx] = {
                  ...newQuiz.questions[idx],
                  ...obj,
                  modified: true,
                }
                setQuiz(newQuiz);
              }
              return (
                <div key={idx} style={{ flex: 1, width: '100%', minWidth: '300px', maxWidth: '600px' }}>
                  <br />
                  <Card
                    sx={{
                      width: '100%',
                      backgroundColor: question.modified ? 'rgb(255,230,230)' : 'default',
                    }}
                  >
                    {/*<CardMedia
                      component="img"
                      alt=""
                      height="140"
                      image={quiz.thumbnailUrl}
                    />*/}
                    <CardContent>
                      <div
                        style={{ display: 'inline-block', float: 'right' }}
                      >
                        <button onClick={() => {
                          api.del(`/v${iter===2?'1':'2'}/admin/quiz/${params.quizid}/question/${question.questionId}`, {}, token)
                          .then(() => {
                            const newQuiz = {...quiz};
                            newQuiz.questions.splice(idx, 1);
                            setQuiz(newQuiz);
                          })
                        }}><Delete /></button>
                        <button disabled={quiz.questions.filter(q => q.modified).length !== 0} onClick={() => {
                          api.post(`/v${iter===2?'1':'2'}/admin/quiz/${params.quizid}/question/${question.questionId}/duplicate`, {}, token)
                          .then(() => {
                            getQuiz();
                          })
                        }}><ContentCopy /></button>
                        {idx > 0 && <button disabled={quiz.questions.filter(q => q.modified).length !== 0} onClick={() => {
                          api.put(`/v${iter===2?'1':'2'}/admin/quiz/${params.quizid}/question/${question.questionId}/move`, {
                            newPosition: idx - 1,
                          }, token)
                          .then(() => {
                            getQuiz();
                          })
                        }}><ArrowBack /></button>}
                        {idx < quiz.questions.length - 1 && <button disabled={quiz.questions.filter(q => q.modified).length !== 0} onClick={() => {
                          api.put(`/v${iter===2?'1':'2'}/admin/quiz/${params.quizid}/question/${question.questionId}/move`, {
                            newPosition: idx + 1,
                          }, token)
                          .then(() => {
                            getQuiz();
                          })
                        }}><ArrowForward /></button>}
                      </div>
                      <Typography gutterBottom variant="h5" component="div">
                        Question {idx + 1}
                      </Typography>
                      <br />
                      <TextField
                        variant="outlined"
                        value={question.question}
                        onChange={e => updateField({
                          question: e.target.value,
                        })}
                        type="text"
                        label="Question"
                      /><br /><br />
                      <TextField
                        variant="outlined"
                        value={question.thumbnailUrl}
                        onChange={e => updateField({
                          thumbnailUrl: e.target.value,
                        })}
                        type="text"
                        label="Thumbnail URL"
                      /><br /><br />
                      Duration: {question.duration} seconds &nbsp;
                      {question.duration > 5 && <button onClick={e => updateField({
                        duration: question.duration - 5,
                      })}>-</button>}
                      <button onClick={e => updateField({
                        duration: question.duration + 5,
                      })}>+</button>
                      <br />
                      Points: {question.points} point &nbsp;
                      {question.points > 1 && <button onClick={e => updateField({
                        points: question.points - 1
                      })}>-</button>}
                      <button onClick={e => updateField({
                        points: question.points + 1,
                      })}>+</button>
                      <br />
                      <br />
                      <hr />
                      <br />
                      {question.answers && question.answers.map((answer, idx2) => {
                        return (
                          <div key={idx2}>
                            <TextField
                              variant="outlined"
                              value={answer.answer}
                              onChange={e => {
                                const newAnswers = [...question.answers];
                                newAnswers[idx2].answer = e.target.value
                                updateField({
                                  answers: newAnswers,
                                });
                              }}
                              type="text"
                              label={`Answer ${idx2 + 1}`}
                              sx={{ width: '160px' }}
                            />
                            <Done
                              onClick={e => {
                                const newAnswers = [...question.answers];
                                newAnswers[idx2].correct = true
                                updateField({
                                  answers: newAnswers,
                                });                      
                              }}
                              sx={{
                                opacity: answer.correct ? 1 : 0.2,
                                cursor: answer.correct ? 'default' : 'pointer',
                                display: 'inline-block',
                                marginTop: '10px',
                                marginLeft: '10px'
                              }}
                            />
                            <Close
                              onClick={e => {
                                const newAnswers = [...question.answers];
                                newAnswers[idx2].correct = false
                                updateField({
                                  answers: newAnswers,
                                });                      
                              }}
                              sx={{
                                opacity: answer.correct ? 0.2 : 1,
                                cursor: answer.correct ? 'pointer' : 'default',
                                display: 'inline-block',
                              }}
                            />
                            <Delete
                              onClick={() => {
                                const newAnswers = [...question.answers];
                                newAnswers.splice(idx2, 1);
                                updateField({
                                  answers: newAnswers,
                                });                      
                              }}
                              sx={{
                                cursor: 'pointer',
                                display: 'inline-block',
                              }}
                            />
                            <br /><br />
                          </div>
                        )
                      })}
                      <br />
                      <AddIcon
                        onClick={() => {
                          const newAnswers = [...question.answers];
                          newAnswers.push({
                            answer: '',
                            correct: false,
                          })
                          updateField({
                            answers: newAnswers,
                            modified: true,
                          });
                        }}
                        sx={{
                          cursor: 'pointer',
                        }}
                      />
                      <br />
                      <br />
                      {question.modified && <Button variant="contained"
                        onClick={() => saveQuizQuestion(idx)}>Save Question</Button>}
                    </CardContent>
                  </Card>
                </div>
              )
            })}
          </div>
          <Card
            sx={{
              width: '250px',
            }}
          >
            <CardContent>
              <Button
                variant="contained"
                onClick={addQuestion}
              >
                Add New Question
              </Button>
            </CardContent>
          </Card>
        </>
      )}
    </>
  )
}

export default QuizSingleEdit;