import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const QuizCard = ({
  iter,
  quiz,
  onClick,
  children,
  blockCursor,
  sx
}) => {
  return (
    <Card
      sx={{
        cursor: blockCursor ? 'default' : 'pointer',
        flexGrow: 1,
        width: '100%',
        maxWidth: 250,
        ...sx,
      }}
      onClick={onClick}
    >
      {iter === 3 && <CardMedia
        component="img"
        alt=""
        height="140"
        image={quiz.thumbnailUrl}
      />}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {quiz.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {quiz.description}
        </Typography>
      </CardContent>
      <CardActions>
        {children}
      </CardActions>
    </Card>
  );
}

export default QuizCard;